/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../../common/images/footerlogo.png";
/* import images */
import locationImg from "../../common/images/location.png";
import teleImg from "../../common/images/tele.png";
import emailImg from "../../common/images/email.png";
import { addressFormat } from "../Helpers/SettingHelper";
import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS } from "../../actions";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [] };
  }

  componentDidMount() {
    this.props.getStaticBlock();
    this.props.getGlobalSettings();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
    if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="footer-topbox">
          <div className="container">
            <div className="row">
              <div className="ntea-cols footer-address">
                <div className="logos">
                  <img src={footerLogo} alt="Logo" />
                </div>
                <div>
                  <div className="nteaaddress">
                    <img src={locationImg} alt="No. 17" />
                    <h5>Address</h5>
                  </div>
                  <p>
                    {addressFormat(
                      this.state.globalsettings.client_unit_number1,
                      this.state.globalsettings.client_unit_number2,
                      this.state.globalsettings.client_address_line1,
                      this.state.globalsettings.client_address_line2,
                      this.state.globalsettings.client_postal_code
                    )}
                  </p>
                </div>
                <div>
                  <div className="nteaaddress">
                    <img src={teleImg} alt="telephone" />

                    <h5>Telephone</h5>
                  </div>
                  <p>
                    <a
                      href={
                        "tel:" + this.state.globalsettings.client_company_phone
                      }
                    >
                      {this.state.globalsettings.client_company_phone}
                    </a>{" "}
                  </p>
                </div>
                <div>
                  <div className="nteaaddress">
                    <img src={emailImg} alt="email" />

                    <h5>Email</h5>
                  </div>
                  <p>
                    <a
                      href={
                        "mailto:" +
                        this.state.globalsettings.client_email_address
                      }
                      className="email"
                    >
                      {this.state.globalsettings.client_email_address}
                    </a>
                  </p>
                </div>
              </div>
              {this.state.footerlink}
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>Copyright {yearSp} No. 17 Tea. All rights reserved.</p>
        </div>

        <div className="scrolltop" id="scrollbutton"> 
        <a href="#">
          <span>
          <i className="fa fa-angle-double-up ars"></i>
          <i className="fa fa-angle-up ars1"></i>
          <i className="fa fa-angle-up ars2"></i>
          </span>
        </a>
      </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
