import { SET_CATERING_HALL } from '../actions';

const cateringhall = (state = [], action) => {
  switch (action.type) {
    case SET_CATERING_HALL:
      return [...action.value];
    default: return state;
  }
}

export default cateringhall;