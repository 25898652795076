import { SET_CARTTIMEDATA } from "../actions";

const carttime = (state = [], action) => {
  switch (action.type) {
    case SET_CARTTIMEDATA:
      return [...action.value];
    default:
      return state;
  }
};

export default carttime;
