import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CATERING_HALL, SET_CATERING_HALL } from '../actions';
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetHall = function* () {
  yield takeEvery(GET_CATERING_HALL, workerGetHall);
}

function* workerGetHall({ hallSlug }) {
  try {
	var slugTxt = (hallSlug !== '') ? '&hallsearchslug='+hallSlug : '';  
    const uri = apiUrlV2+'catering/cateringHalls?app_id='+appId+slugTxt;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_CATERING_HALL, value: resultArr });
  } 
  catch {
    console.log('Get Catering halls Failed');
  }
}
