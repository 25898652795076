/* Live */
export const appId = "EA7E4016-B293-4C80-BD54-A7E1E9CDBEAF";

export const apiUrl = "https://ccpl.ninjaos.dev/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.dev/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.dev/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.dev/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.dev/Pushorder/";
export const baseUrl = "http://noseventeen.promobuddy.asia/";
//export const baseUrl = "http://localhost:3002/";
export const timThumpUrl = "https://ccpl.ninjaos.dev/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.dev/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.dev/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.dev/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.dev/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "No. 17";

export const fbAppId = "943922873511961";
export const googleAppId =
  "222562700631-d0190i2e444rou3ah7q4jc8qekp62s6h.apps.googleusercontent.com";

export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "noseventeen";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
