/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CATERINGHISTORY, SET_CATERINGHISTORY } from "../actions";
import { appId, apiUrlCtrng, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetCateringHistory = function* () {
  yield takeEvery(GET_CATERINGHISTORY, workerGetCateringHistory);
};

function* workerGetCateringHistory({ historyparams }) {
  try {
    const uri =
      apiUrlCtrng + "reports/order_history?app_id=" + appId + historyparams;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CATERINGHISTORY, value: resultArr });
  } catch {
    console.log("Get Catering order Failed");
  }
}
