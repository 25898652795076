/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
  capitalize
} from "../Helpers/SettingHelper";
import { setMinutes, setHours, getDay, format } from "date-fns";
import noimage from "../../common/images/no-img-product.png";
import like from "../../common/images/cat-item-like-big.png";
import likehover from "../../common/images/cat-item-like-active.png";
import { isNull } from "underscore";
import { GET_PRODUCT_LIST, GET_FAVOURITE } from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    var cust_id =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== "undefined" &&
      typeof cookie.load("UserId") !== undefined
        ? +cookie.load("UserId")
        : "";
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      product_List: [],
      filter_Tag: [],
      favproductID: [],
      favdeleteproductID: [],
      cust_id: cust_id,
      imagesource: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        // this.props.getProductList(catSlug, subcatSlug, "");
        this.getCateProductList(nextProps.productState.navigateMenu);
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }

  getCateProductList(navigateMenu) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      var updateproductList = [];
      var current = this;
      navigateMenu.map(function (item) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletId=" +
            cookie.load("orderOutletId")
        )
          .then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              //if (navigateMenu.length - 1 === index) {
              current.productsubcatlist(updateproductList, navigateMenu);
              // }
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  // productsubcatlist() {
  //   var subcatlistArr = this.props.productSubcatlist;
  //   if (Object.keys(subcatlistArr).length > 0) {
  //     hideLoader("productlist-main-div", "class");
  //     const productDetails = subcatlistArr.map((categories, categoryIndex) => {
  //       if (categories.products.length > 0) {
  //         return (
  //           <div className="innerproduct" key={categoryIndex}>
  //             <h3 id={categories.pro_subcate_slug}>
  //               {stripslashes(categories.pro_subcate_name)}
  //             </h3>
  //             <p className="description">
  //               Lorem Ipsum is simply dummy text of the printing and typesetting
  //               industry. Lorem Ipsum has been the industry's standard dummy
  //               text ever since the 1500s,
  //             </p>
  //             <ul className="products-list-ulmain">
  //               {this.productList(
  //                 categories.products,
  //                 categories.products.length,
  //                 categories.pro_subcate_slug
  //               )}
  //             </ul>
  //           </div>
  //         );
  //       }
  //     });
  //     this.setState({ productDetails: productDetails });
  //     return productDetails;
  //   } else {
  //     return "";
  //   }
  // }

  productsubcatlist(updateproductList, navigateMenu) {
    let current = this;

    if (navigateMenu !== "" && navigateMenu !== undefined) {
      this.setState({
        OldupdateproductList: updateproductList,
        oldnavigateMenu: navigateMenu,
      });
      if (navigateMenu !== "" && navigateMenu.length > 0) {
        var product_List = navigateMenu.map(function (item, index) {
          return (
            <div id={item.pro_cate_slug} key={index}>
              <div
                className="product-category-list"
                key={index}
                id={"pro_cat_" + item.pro_cate_slug}
              >
                <h2>{capitalize(stripslashes(item.pro_cate_name))}</h2>
                {item.pro_cate_short_description !== "" && (
                  <p>{stripslashes(item.pro_cate_short_description)}</p>
                )}
                {updateproductList[item.pro_cate_slug] !== "" &&
                updateproductList[item.pro_cate_slug] !== undefined
                  ? updateproductList[item.pro_cate_slug][0].subcategorie.map(
                      function (categories, sindex) {
                        return (
                          <div className="innerproduct" id={"pro_cat_" + categories.pro_subcate_slug} key={sindex}>
                            <h3>{stripslashes(capitalize(categories.pro_subcate_name))}</h3>
                            <ul className="products-list-ulmain">
                              {current.productList(
                                categories.products,
                                categories.products.length
                              )}
                            </ul>
                          </div>
                        );
                      }
                    )
                  : ""}
              </div>
            </div>
          );
        });
        this.setState({ product_List: product_List }, function () {
          hideLoader("productlist-main-div", "class");
        });
      }
    }
  }

  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_special_price !== ""
        ? parseFloat(productDetail.product_special_price)
        : 0;
    if (
      productSpecialPrice > 0 &&
      productDetail.product_special_price_from_date !== "" &&
      productDetail.product_special_price_to_date !== ""
    ) {
      var datetime = new Date();
      var crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");
      var crtDatefrmtObj = new Date(crtDatefrmtTxt);
      var proSplFromdate = new Date(
        productDetail.product_special_price_from_date
      );
      var proSplTodate = new Date(productDetail.product_special_price_to_date);
      if (proSplFromdate <= crtDatefrmtObj && proSplTodate >= crtDatefrmtObj) {
        return (
          <div>
            <div className="product-spl-price">
              <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
              {/* <h3>{"S$" + productDetail.product_price}</h3> */}
            </div>
            <div className="product-price">
              {productDetail.product_special_price > 0 ? (
                <h3>
                  {Parser(showPriceValue(productDetail.product_special_price))}
                </h3>
              ) : (
                <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="product-spl-price">
              <h3></h3>
            </div>
            <div className="product-price">
              <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="product-spl-price">
            <h3></h3>
          </div>
          <div className="product-price">
            <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
          </div>
        </div>
      );
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list, productcount, pro_subcate_slug) {
    // console.log(this.state.imagesource); return false;
    var imageSource = this.state.imagesource.product_image_source;
    var tagImageSource = this.state.imagesource.tag_image_source;
    var slugType = this.state.selectedslugType;
    var naviSlug = this.state.selectedNavigation;
    var totalProductShow = 0;
    var listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let filter = this.checkFilterTag(productDetail.product_tag);
      if (filter === 1) {
        totalProductShow++;
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
          >
            <div className="products-image-div">
              {/* <span
                className={
                  this.state.cust_id !== ""
                    ? this.state.favdeleteproductID.indexOf(
                        productDetail.product_primary_id
                      ) >= 0
                      ? "like"
                      : !isNull(productDetail.fav_product_primary_id) ||
                        this.state.favproductID.indexOf(
                          productDetail.product_primary_id
                        ) >= 0
                      ? "like active"
                      : "like"
                    : "like"
                }
              >
                {this.state.cust_id !== "" ? (
                  this.state.favdeleteproductID.indexOf(
                    productDetail.product_primary_id
                  ) >= 0 ? (
                    <img
                      src={like}
                      className="no_hover"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "Yes"
                      )}
                    />
                  ) : !isNull(productDetail.fav_product_primary_id) ||
                    this.state.favproductID.indexOf(
                      productDetail.product_primary_id
                    ) >= 0 ? (
                    <img
                      src={likehover}
                      className="hover"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "No"
                      )}
                    />
                  ) : (
                    <img
                      src={like}
                      className="no_hover"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "Yes"
                      )}
                    />
                  )
                ) : (
                  <img
                    src={like}
                    className="no_hover"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  />
                )}
              </span> */}
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h3>
                </div>

                {Object.keys(productDetail.product_tag).length > 0 ? (
                  <div className="product-tag-list">
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        if (producttag.pro_tag_name !== "") {
                          return (
                            <li key={index1}>{producttag.pro_tag_name}</li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {description !== "" && (
                <div className="product-short-description">
                  <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                </div>
              )}

              {this.getProductSplPrice(productDetail)}

              {/* <div className="product-price">
                <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
              </div> */}

              <div className="products-ordernow-action">
                {productDetail.product_stock > 0 ||
                productDetail.product_stock === null ? (
                  productDetail.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "initial"
                      )}
                    >
                      Add to Cart
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, productDetail)}
                      title="Product Details"
                      id={comboProId}
                      className="button order_nowdiv compo_product_lk"
                    >
                      Order Now
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    Sold Out
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="addcart-row-child">
                    <div className="qty_bx nteaqty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "decr"
                        )}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        className="proqty_input"
                        readOnly
                        value="1"
                      />
                      <span
                        className="qty_plus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "incr"
                        )}
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="btn btn_black "
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "done"
                      )}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
    });
    if (totalProductShow > 0) {
      $("#" + pro_subcate_slug).show();
      return listProduct;
    } else {
      $("#" + pro_subcate_slug).hide();
      listProduct = (
        <div>
          <h3>No Product Found</h3>
        </div>
      );
      return listProduct;
    }
  }
  addFavourite(productID, fav_flag) {
    if (this.state.cust_id === "") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
      return false;
    }
    var formData = {
      app_id: appId,
      product_id: productID,
      fav_flag: fav_flag,
      customer_id: cookie.load("UserId"),
    };
    var favproductID = this.state.favproductID;
    var favdeleteproductID = this.state.favdeleteproductID;
    if (fav_flag === "Yes") {
      favproductID.push(productID);
      const index1 = favdeleteproductID.indexOf(productID);
      if (index1 > -1) {
        favdeleteproductID.splice(index1, 1);
      }
    } else {
      const index = favproductID.indexOf(productID);
      if (index > -1) {
        favproductID.splice(index, 1);
      }
      favdeleteproductID.push(productID);
    }
    this.setState(
      { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
      function () {
        this.productsubcatlist();
      }
    );

    var postObject = qs.stringify(formData);
    this.props.getFavouriteproducts(postObject);
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          /* showCustomAlert("success", "Great choice! Item added to your cart."); */
          /*showCartLst();*/
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    return (
      <div className="productlist-main-div">
        <div className="innerproduct_row">
          {this.state.product_List}
          {this.state.productDetails}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
