import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_RES_ORDER_SUBMIT, SET_RES_ORDER_SUBMIT } from '../actions';
import { apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
var qs = require('qs');

export const watchGetgetResOrdersubmit = function* () {
  yield takeEvery(GET_RES_ORDER_SUBMIT, workergetResOrdersubmit);
}

function* workergetResOrdersubmit({ resOrderFlg }) {
  try {
		var postObject = {};
		
		postObject = { 'app_id': resOrderFlg['app_id'], 'order_source': resOrderFlg['order_source'], 'availability_id': resOrderFlg['availability_id'], 'customer_fname': resOrderFlg['customer_fname'], 'customer_lname': resOrderFlg['customer_lname'],'customer_email':resOrderFlg['customer_email'],'customer_mobile_no':resOrderFlg['customer_mobile_no'],'customer_id':resOrderFlg['customer_id'],'reservation_datetime':resOrderFlg['reservation_datetime'],'reservation_outlet_id':resOrderFlg['reservation_outlet_id'],'reservation_outlet_time':resOrderFlg['reservation_outlet_time'],'reservation_time_slot':resOrderFlg['reservation_time_slot'],'reservation_start_time':resOrderFlg['reservation_start_time'],'reservation_end_time':resOrderFlg['reservation_end_time'],'reservation_outlet_name':resOrderFlg['reservation_outlet_name'],'reservation_no_of_adult_pax':resOrderFlg['reservation_no_of_adult_pax'],'reservation_no_of_children_pax':resOrderFlg['reservation_no_of_children_pax'],'reservation_instruction':resOrderFlg['reservation_instruction'],'reservation_status':resOrderFlg['reservation_status']};
	
		const uri = apiUrl+'reservation1/reservation_submit_order';
		const result = yield call(Axios.post, uri, qs.stringify(postObject));	
		var resultArr = [];
		console.log('orderFt',result.data);
		resultArr.push(result.data);
		yield put({ type: SET_RES_ORDER_SUBMIT, value: resultArr });
  } 
  catch {
    console.log('Get Availabile Date Failed');
  }
}
