import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CATERING_PRODCUTLIST, SET_CATERING_PRODCUTLIST } from "../actions";
import { appId, apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCateringProduct = function* () {
  yield takeEvery(GET_CATERING_PRODCUTLIST, workerGetCateringProduct);
};

function* workerGetCateringProduct({ parameter }) {
  try {
    const uri =
      apiUrlCtrng + "catering/products_list?app_id=" + appId + parameter;

    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CATERING_PRODCUTLIST, value: resultArr });
  } catch (e) {
    console.log("Get Catering Product Failed", e);
  }
}
