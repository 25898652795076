import { SET_UPLOAD_IMAGE } from "../actions";

const uploadDataImage = (state = [], action) => {
  switch (action.type) {
    case SET_UPLOAD_IMAGE:
      return [...action.value];
    default:
      return state;
  }
};

export default uploadDataImage;
