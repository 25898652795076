import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_OWNVENUE_CATEGORY, SET_OWNVENUE_CATEGORY } from '../actions';
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetOwnvenueCategory = function* () {
  yield takeEvery(GET_OWNVENUE_CATEGORY, workerGetOwnvenueCategory);
}

function* workerGetOwnvenueCategory() {
  try {
    const uri = apiUrlV2+'catering/categories?app_id='+appId+"&cat_venue_type=ownvenue&cat_product_val=no";
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_OWNVENUE_CATEGORY, value: resultArr });
  } 
  catch {
    console.log('Get Category Failed');
  }
}
