/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import Slider from "react-slick";
import { appId, apiUrlV2, deliveryId } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  capitalize,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
var qs = require("qs");

import { GET_PROMOTIONPRO } from "../../../actions";

var Parser = require("html-react-parser");

import promotionImg from "../../../common/images/1.jpg";

import promotiontwoImg from "../../../common/images/4.jpg";

import promotionthreeImg from "../../../common/images/5.jpg";

import promotionfourImg from "../../../common/images/2.jpg";

import promotionfiveImg from "../../../common/images/3.jpg";

import promotionsixImg from "../../../common/images/6.jpg";

class PromotionedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.props.getPromotionProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        if (
          (productDetail.product_maximum_quantity > 0 &&
            proqtyQty > productDetail.product_maximum_quantity) ||
          (productDetail.product_minimum_quantity > 0 &&
            proqtyQty < productDetail.product_minimum_quantity)
        ) {
          this.handleShowAlertFun(
            "Error",
            "Please choose Minimum " +
              productDetail.product_minimum_quantity +
              " or Maximum " +
              productDetail.product_maximum_quantity
          );
          return false;
        }
        showLoader("proIndex-" + IndexFlg, "Idtext");

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            // showCustomAlert(
            //   "success",
            //   "Great choice! Item added to your cart."
            // );
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "PromotionedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "PromotionedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  firstRowProducts(promotionprolist, promotionproimagesource) {
    var imgSrc = noimage;

    const runCallback = (cb) => {
      return cb();
    };

    return (
      <div className="flot">
        {runCallback(() => {
          const row = [];
          for (var i = 0; i < 6; i++) {
            if (i == 0) {
              var prodivId =
                "proIndex-" + promotionprolist[0].product_primary_id;
              var comboProId = "comboPro-" + promotionprolist[0].product_slug;
              var proImage =
                promotionprolist[0].product_thumbnail !== "" &&
                promotionprolist[0].product_thumbnail !== null
                  ? promotionproimagesource +
                    "/" +
                    promotionprolist[0].product_thumbnail
                  : promotionImg;
              row.push(
                <div className="promote">
                  <img src={proImage} />
                  <div className="centers">
                    <h4>
                      {stripslashes(
                        capitalize(promotionprolist[0].product_name)
                      )}
                    </h4>
                    <p>{promotionprolist[0].product_short_description}</p>
                    <div className="common-btn">
                      {promotionprolist[0].product_stock > 0 ||
                      promotionprolist[0].product_stock === null ? (
                        promotionprolist[0].product_type === "1" ? (
                          <a
                            className="view button order_nowdiv smiple_product_lk disbl_href_action"
                            href="/"
                            onClick={this.addToCartSimple.bind(
                              this,
                              promotionprolist[0],
                              "initial"
                            )}
                          >
                            ADD TO CART
                          </a>
                        ) : (
                          <a
                            href="/"
                            onClick={this.viewProDetail.bind(
                              this,
                              promotionprolist[0]
                            )}
                            title="Product Details"
                            id={comboProId}
                            className="view button order_nowdiv compo_product_lk"
                          >
                            Order Now
                          </a>
                        )
                      ) : (
                        <a
                          className="button disabled disbl_href_action"
                          href="/"
                        >
                          Sold Out
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          }
          return row;
        })}
        <div className="flttwo">
          {runCallback(() => {
            const row = [];
            for (var i = 0; i < 6; i++) {
              if (i == 3) {
                var prodivId =
                  "proIndex-" + promotionprolist[3].product_primary_id;
                var comboProId = "comboPro-" + promotionprolist[3].product_slug;
                var proImage =
                  promotionprolist[3].product_thumbnail !== "" &&
                  promotionprolist[3].product_thumbnail !== null
                    ? promotionproimagesource +
                      "/" +
                      promotionprolist[3].product_thumbnail
                    : promotionfourImg;
                row.push(
                  <div className="promotefour">
                    <img src={proImage} />
                    <div className="centertwo">
                      <h4>
                        {stripslashes(
                          capitalize(promotionprolist[3].product_name)
                        )}
                      </h4>
                      <div className="common-btn text-center">
                        {promotionprolist[3].product_stock > 0 ||
                        promotionprolist[3].product_stock === null ? (
                          promotionprolist[3].product_type === "1" ? (
                            <a
                              className="view button order_nowdiv smiple_product_lk disbl_href_action"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                promotionprolist[3],
                                "initial"
                              )}
                            >
                              ADD TO CART
                            </a>
                          ) : (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(
                                this,
                                promotionprolist[3]
                              )}
                              title="Product Details"
                              id={comboProId}
                              className="view button order_nowdiv compo_product_lk"
                            >
                              Order Now
                            </a>
                          )
                        ) : (
                          <a
                            className="button disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }

              if (i == 4) {
                var prodivId =
                  "proIndex-" + promotionprolist[4].product_primary_id;
                var comboProId = "comboPro-" + promotionprolist[4].product_slug;
                var proImage =
                  promotionprolist[4].product_thumbnail !== "" &&
                  promotionprolist[4].product_thumbnail !== null
                    ? promotionproimagesource +
                      "/" +
                      promotionprolist[4].product_thumbnail
                    : promotionfiveImg;
                row.push(
                  <div className="promotefive">
                    <img src={proImage} />
                    <div className="centertwos ">
                      <h4>
                        {stripslashes(
                          capitalize(promotionprolist[4].product_name)
                        )}
                      </h4>
                      <div className="common-btn text-left">
                        {promotionprolist[4].product_stock > 0 ||
                        promotionprolist[4].product_stock === null ? (
                          promotionprolist[4].product_type === "1" ? (
                            <a
                              className="view button order_nowdiv smiple_product_lk disbl_href_action"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                promotionprolist[4],
                                "initial"
                              )}
                            >
                              ADD TO CART
                            </a>
                          ) : (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(
                                this,
                                promotionprolist[4]
                              )}
                              title="Product Details"
                              id={comboProId}
                              className="view button order_nowdiv compo_product_lk"
                            >
                              Order Now
                            </a>
                          )
                        ) : (
                          <a
                            className="button disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            }
            return row;
          })}
        </div>
      </div>
    );
  }

  secondRowProducts(promotionprolist, promotionproimagesource) {
    var imgSrc = noimage;

    const runCallback = (cb) => {
      return cb();
    };

    return (
      <div className="flottwo">
        <div className="flt">
          {runCallback(() => {
            const row = [];
            for (var i = 0; i < 6; i++) {
              if (i == 1) {
                var prodivId =
                  "proIndex-" + promotionprolist[1].product_primary_id;
                var comboProId = "comboPro-" + promotionprolist[1].product_slug;
                var proImage =
                  promotionprolist[1].product_thumbnail !== "" &&
                  promotionprolist[1].product_thumbnail !== null
                    ? promotionproimagesource +
                      "/" +
                      promotionprolist[1].product_thumbnail
                    : promotiontwoImg;
                row.push(
                  <div className="promotetwo">
                    <img src={proImage} />
                    <div className="centertwo promo">
                      <h4>
                        {stripslashes(
                          capitalize(promotionprolist[1].product_name)
                        )}
                      </h4>
                      <div className="common-btn">
                        {promotionprolist[1].product_stock > 0 ||
                        promotionprolist[1].product_stock === null ? (
                          promotionprolist[1].product_type === "1" ? (
                            <a
                              className="view button order_nowdiv smiple_product_lk disbl_href_action"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                promotionprolist[1],
                                "initial"
                              )}
                            >
                              ADD TO CART
                            </a>
                          ) : (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(
                                this,
                                promotionprolist[1]
                              )}
                              title="Product Details"
                              id={comboProId}
                              className="view button order_nowdiv compo_product_lk"
                            >
                              Order Now
                            </a>
                          )
                        ) : (
                          <a
                            className="button disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }

              if (i == 2) {
                var prodivId =
                  "proIndex-" + promotionprolist[2].product_primary_id;
                var comboProId = "comboPro-" + promotionprolist[2].product_slug;
                var proImage =
                  promotionprolist[2].product_thumbnail !== "" &&
                  promotionprolist[2].product_thumbnail !== null
                    ? promotionproimagesource +
                      "/" +
                      promotionprolist[2].product_thumbnail
                    : promotionthreeImg;
                row.push(
                  <div className="promotethree">
                    <img src={proImage} />
                    <div className="centertwo promo">
                      <h4>
                        {stripslashes(
                          capitalize(promotionprolist[2].product_name)
                        )}
                      </h4>
                      <div className="common-btn">
                        {promotionprolist[2].product_stock > 0 ||
                        promotionprolist[2].product_stock === null ? (
                          promotionprolist[2].product_type === "1" ? (
                            <a
                              className="view button order_nowdiv smiple_product_lk disbl_href_action"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                promotionprolist[2],
                                "initial"
                              )}
                            >
                              ADD TO CART
                            </a>
                          ) : (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(
                                this,
                                promotionprolist[2]
                              )}
                              title="Product Details"
                              id={comboProId}
                              className="view button order_nowdiv compo_product_lk"
                            >
                              Order Now
                            </a>
                          )
                        ) : (
                          <a
                            className="button disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            }
            return row;
          })}
        </div>

        {runCallback(() => {
          const row = [];
          for (var i = 0; i < 6; i++) {
            if (i == 5) {
				if (
					promotionprolist[5] !== "" &&
					promotionprolist[5] !== null &&
					promotionprolist[5] !== undefined &&
					promotionprolist[5] !== "undefined"
				  ) {
				  var prodivId =
					"proIndex-" + promotionprolist[5].product_primary_id;
				  var comboProId = "comboPro-" + promotionprolist[5].product_slug;
				  var proImage =
					promotionprolist[5].product_thumbnail !== "" &&
					promotionprolist[5].product_thumbnail !== null
					  ? promotionproimagesource +
						"/" +
						promotionprolist[5].product_thumbnail
					  : promotionsixImg;
				  row.push(
					<div className="promotesix">
					  <img src={proImage} />
					  <div className="centers cen common-btn">
						<h4>
						  {stripslashes(
							capitalize(promotionprolist[5].product_name)
						  )}
						</h4>
						<p>{promotionprolist[5].product_short_description}</p>
						<div>
						  {promotionprolist[5].product_stock > 0 ||
						  promotionprolist[5].product_stock === null ? (
							promotionprolist[5].product_type === "1" ? (
							  <a
								className="view button order_nowdiv smiple_product_lk disbl_href_action"
								href="/"
								onClick={this.addToCartSimple.bind(
								  this,
								  promotionprolist[5],
								  "initial"
								)}
							  >
								ADD TO CART
							  </a>
							) : (
							  <a
								href="/"
								onClick={this.viewProDetail.bind(
								  this,
								  promotionprolist[5]
								)}
								title="Product Details"
								id={comboProId}
								className="view button order_nowdiv compo_product_lk"
							  >
								Order Now
							  </a>
							)
						  ) : (
							<a
							  className="button disabled disbl_href_action"
							  href="/"
							>
							  Sold Out
							</a>
						  )}
						</div>
					  </div>
					</div>
				  );
				}
            }
          }
          return row;
        })}
      </div>
    );
  }

  render() {
    var Ourspecialdishes = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let promotionproArr = this.props.promotionproduct;
    // console.log(promotionproArr);
    // return false;
    let promotionprolist = [];
    let promotionproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(promotionproArr).length > 0) {
      if (promotionproArr[0].status === "ok") {
        promotionprolist = promotionproArr[0].result_set;
        promotionproimagesource = promotionproArr[0].common.image_source;
        tagimagesource = promotionproArr[0].common.tag_image_source;
      }
    }

    if (promotionprolist.length > 0) {
      return (
        <section className="nteaourpromotion-section">
          <div className="ourpromotion-section">
            <div className="container">
              <h2>Our Promotions</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>

              {this.firstRowProducts(promotionprolist, promotionproimagesource)}
              {this.secondRowProducts(
                promotionprolist,
                promotionproimagesource
              )}
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <div className="container">
          <h2>Our Promotions</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  return {
    promotionproduct: state.promotionproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionProList: () => {
      dispatch({ type: GET_PROMOTIONPRO });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(PromotionedProducts);
