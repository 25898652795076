/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PROMOTIONPRO, SET_PROMOTIONPRO } from "../actions";
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetPromotionPro = function* () {
  yield takeEvery(GET_PROMOTIONPRO, workerGetPromotionPro);
};

function* workerGetPromotionPro() {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");
    const uri =
      apiUrl +
      "products/promotion_products_list?app_id=" +
      appId +
      "&availability=" +
      availabilityId;
    const result = yield call(Axios.get, uri);
    console.log(uri);
    console.log(result);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PROMOTIONPRO, value: resultArr });
  } catch {
    console.log("Get Promotion Product Failed");
  }
}
