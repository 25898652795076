/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_TESTIMONIALS, SET_TESTIMONIALS } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetTestimonials = function* () {
  yield takeEvery(GET_TESTIMONIALS, workerGetTestimonials);
};

function* workerGetTestimonials() {
  try {
    const uri = apiUrl + "cms/testimonial?app_id=" + appId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TESTIMONIALS, value: resultArr });
  } catch {
    console.log("TESTIMONIALS failed");
  }
}
