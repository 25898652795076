import { SET_CATERINGCART_DETAIL } from '../actions';

const cateringcartlistdetail = (state = [], action) => {
  switch (action.type) {
    case SET_CATERINGCART_DETAIL:
      return [...action.value];
    default: return state;
  }
}

export default cateringcartlistdetail;