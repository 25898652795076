import { takeEvery, call, put } from "redux-saga/effects";
import { GET_DELETE_IMAGE, SET_DELETE_IMAGE } from "../actions";
import { apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetDeleteImage = function* () {
  yield takeEvery(GET_DELETE_IMAGE, workerGetDeleteImage);
};

function* workerGetDeleteImage(obj) {
  try {
    const uri = apiUrlCtrng + "cart/unlinkImg";
    const result = yield call(Axios.post, uri, obj.obj);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_DELETE_IMAGE, value: resultArr });
  } catch {
    console.log("Get Delete Logo Failed");
  }
}
