import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_CATERING_PRODCUTDETAIL,
  SET_CATERING_PRODCUTDETAIL,
} from "../actions";
import { appId, apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCateringProductDetail = function* () {
  yield takeEvery(GET_CATERING_PRODCUTDETAIL, workerGetCateringProductDetail);
};

function* workerGetCateringProductDetail({ proSlug }) {
  try {
    const uri =
      apiUrlCtrng + "catering/products_list?app_id=" + appId + proSlug;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CATERING_PRODCUTDETAIL, value: resultArr });
  } catch {
    console.log("Get Catering Product Detail Failed");
  }
}
