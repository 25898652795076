/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import { setMinutes, setHours, getDay, format } from "date-fns";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removePromoCkValue,
  resetCrtStyle,
} from "../Helpers/SettingHelper";
import { deliveryId, cateringId } from "../Helpers/Config";

import trashImg from "../../common/images/trash-bin.png";
import crossImg from "../../common/images/cross1.png";
import shoppingBag from "../../common/images/shopping-bag.svg";
import productImg from "../../common/images/no-img-product.png";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_CATERINGCART_DETAIL,
} from "../../actions";
var Parser = require("html-react-parser");

class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
        ? cookie.load("orderOutletId")
        : "";
    var orderTAT =
      cookie.load("orderTAT") !== "" &&
      typeof cookie.load("orderTAT") !== undefined &&
      typeof cookie.load("orderTAT") !== "undefined"
        ? cookie.load("orderTAT")
        : "";
    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      defaultAvilablityId: defaultAvilablityId,
      seletedAvilablityId: defaultAvilablityId,
      seletedOutletId: orderOutletId,
      order_tat_time: orderTAT,
      getDateTimeFlg: "yes",
      initialSlot: true,
    };
  }

  componentWillMount() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    if (avltyTxt === cateringId) {
      this.props.getCateringCartDetail();
    } else {
      this.props.getCartDetail();
    }
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    if (this.state.cartDetails !== headerProps.cartDetails) {
      if (Object.keys(headerProps.cartDetails).length > 0) {
        this.setState({ cartDetails: headerProps.cartDetails });
        if (this.state.initialSlot === true) {
          this.setState({ getDateTimeFlg: "yes", initialSlot: false });
          headerProps.setdateTimeFlg("tmflg", "yes");
        }
      }
    }

    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");
  }

  componentDidMount() {}
  handleChange = (event) => {};
  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: ordTime,
          seleted_ord_slotTxt: ordTime,
          seleted_ord_slot_str: ordTime,
          seleted_ord_slot_end: ordTime,
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: tmSltArr["ordSlotVal"],
          seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
          seleted_ord_slot_str: tmSltArr["ordSlotStr"],
          seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeDataNew() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      cookie.save("orderDateTime", orderDateTime, { path: "/" });
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPadNew(OrderHours) +
        ":" +
        this.convPadNew(OrderMunts) +
        ":" +
        this.convPadNew(OrderSecnd);
      // cookie.save("orderDateTime", orderDateTime, { path: "/" });
      cookie.save("deliveryDate", deliveryDate, { path: "/" });
      cookie.save("deliveryTime", deliveryTime, { path: "/" });

      this.props.getCartDetail();
    }
  }

  convPadNew(d) {
    var rstVl = d < 10 ? "0" + parseInt(d) : d.toString();
    return rstVl.toString();
  }

  changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      /*var calculatedAmount = getCalculatedAmount(globalSettings,cartDetailsArr,promoTionArr);*/

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        0,
        cartItemsArr
      );

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);

      return (
        <div
          className={
            this.props.pageName === "products"
              ? "hcart_dropdown open"
              : "hcart_dropdown"
          }
        >
          <div className="product_orders_top">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>
            <h4>Your Order Details</h4>
          </div>
          <div className="hcart_maiin_scrollarea">
            <div className="hcart_scrollarea">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId ? (
                  <div className="del_address">
                    <div className="hcart_row_head">
                      <div className="hcart_add_item hcart_box">
                        <h5>Order Handeling by</h5>
                        <p>{cookie.load("orderOutletName")}</p>
                        <p>{cookie.load("orderHandledByText")}</p>
                      </div>
                      <div className="hcart_add_loca hcart_boxs">
                        <h5>Delivery Location</h5>
                        <p>
                          {cookie.load("orderDeliveryAddress")}{" "}
                          {cookie.load("orderPostalCode")}
                        </p>
                      </div>
                      <div className="nteahash">
                        <div className="hcart_add_innr_loca">
                          <p>#</p>
                          <div className="form-group">
                            <div className="input_field">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.unitnumber1}
                                name="unit_no1"
                                onChange={this.handleAddrChange.bind(this)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input_field">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.unitnumber2}
                                name="unit_no2"
                                onChange={this.handleAddrChange.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="nteachangedelivery">
                          <p>
                            <span className="changedelivery">
                              <a
                                href="/"
                                onClick={this.changeAddrrFun.bind(this)}
                              >
                                Change Delivery Location
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="hcart_row_head hacrt_sec_title nteadelivery">
                      <OrderAdvancedDatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                        labelDateName="Delivery Date"
                        labelTimeName="Delivery Time"
                        arrowDateTimeSelect={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="">
                        <div className="">
                          <h4>Pickup Location</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="hcart_row_head hacrt_sec_title">
                      <OrderAdvancedDatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                        labelDateName="Pickup Date"
                        labelTimeName="Pickup Time"
                        arrowDateTimeSelect={false}
                      />
                    </div>
                  </div>
                )}
                <div className="overall-parent">
                  <div className="order-details-with-clear">
                    <h5>Your Items</h5>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="hclear_cart"
                      title="CLEAR CART"
                    >
                      clear cart
                      <img src={trashImg} />
                    </a>
                  </div>
                  <div className="product-details-parent">
                    {this.cartItemList()}
                  </div>
                </div>
              </div>

              <div className="cart_footer">
                <div className="cart_footer_back">
                  <div className="cart_row">
                    <p className="text-uppercase">SUBTOTAL</p>
                    <span>S${calculatedAmount["cartSubTotalAmount"]}</span>
                  </div>
                  {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                    <div className="cart_row">
                      <p className="text-uppercase">Delivery</p>

                      <span>
                        S$
                        {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                    <div className="cart_row">
                      <p className="text-uppercase">Additional Delivery</p>

                      <span>
                        S$
                        {parseFloat(
                          calculatedAmount["additionalDelivery"]
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                    <div className="cart_row promo-cart-row">
                      <p className="text-uppercase">Promo Code</p>

                      <span>
                        S$
                        {parseFloat(
                          calculatedAmount["promotionAmount"]
                        ).toFixed(2)}
                      </span>

                      <a
                        href="/"
                        onClick={this.removePromoFun.bind(this)}
                        className="cart_remove"
                      >
                        <img src={crossImg} />
                      </a>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                    <div className="cart_row gst-row">
                      <p className="text-uppercase">
                        GST ({calculatedAmount["orderDisplayGst"]} %)
                      </p>

                      <span>
                        S$
                        {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  )}
                  <div className="cart_row grant-total-cls">
                    <p className="text-uppercase">Total</p>

                    <span>
                      <sup>S$</sup>
                      {calculatedAmount["grandTotalAmount"]}
                    </span>
                  </div>
                </div>

                {this.loadMinPercentage()}
                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentage()}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type} S$
                    {cartDetailsArr.cart_special_discount} Applied
                  </div>
                )}
              </div>
            </div>
            {this.props.pageName === "products" && (
              <div className="cart_action">
                <div className="cartaction_bottom">
                  <Link
                    to={"/checkout"}
                    className="btn btn_pink cartaction_checkout"
                    title="Checkout Now"
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            )}
            {this.props.pageName === "header" && (
              <div className="cart_action">
                <div className="cartaction_bottom">
                  <Link
                    to={"/checkout"}
                    className="btn btn_pink cartaction_checkout"
                    title="Checkout Now"
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      this.closeCartlist();
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        0,
        cartItemsArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_itme_free">
          <div className="cart_row progress_bar_div">
            <div className="indication">
              <div className="indication_progress">
                <span
                  className="progress_bar"
                  style={{ width: delPercentage + "%" }}
                />
              </div>
              <p className="help-block">
                S${remainAmnt} more to FREE delivery!
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadMinPercentage() {
    var freeDeliveryAmnt = 0;
    var minAmnt = 0;
    var remainAmnt = 0;
    var minPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        0,
        cartItemsArr
      );

      minAmnt = parseFloat(calculatedAmount["zoneMinAmount"]);

      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minAmnt;
      percentage = Math.round(percentage);
      minPercentage = percentage;
      remainAmnt = parseFloat(minAmnt - subTotal).toFixed(2);
    }

    if (remainAmnt > 0) {
      return (
        <div className="cart_itme_free">
          <div className="cart_row progress_bar_div">
            <div className="indication">
              <div className="indication_progress">
                <span
                  className="minprogress_bar"
                  style={{ width: minPercentage + "%" }}
                />
              </div>
              <p className="help-block">
                S${remainAmnt} more to Min order Amount!
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="col-sm-cls cart_left">
            <div className="cart_info text-left">
              <div className="cart_into-text">
                {product.cart_item_product_image !== "" ? (
                  <div className="cart_img">
                    <img src={product.cart_item_product_image} alt="" />
                  </div>
                ) : (
                  <img src={productImg} alt="" />
                )}
                <div className="cart_into_txt_cnt">
                  <h4>{stripslashes(product.cart_item_product_name)}</h4>
                  {this.loadModifierItems(
                    product.cart_item_type,
                    product.modifiers,
                    product.set_menu_component
                  )}
                  {product.cart_item_special_notes !== "" && (
                    <p className="cart_into_desc">
                      {stripslashes(product.cart_item_special_notes)}
                    </p>
                  )}
                </div>
              </div>
              {parseFloat(product.cart_item_promotion_discount) > 0 && (
                <span className="member-discount-desc">
                  S$ {product.cart_item_promotion_discount}{" "}
                  {product.cart_item_promotion_type} discount Applied
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-cls cart_right text-right">
            <div className="cart_price">
              <p>S${product.cart_item_total_price}</p>
            </div>
            <div className="qty_bx nteaqty_bx">
              <span
                className="qty_minus"
                onClick={this.updateCartQty.bind(this, product, "decr")}
              >
                -
              </span>
              <input type="text" value={product.cart_item_qty} readOnly />
              <span
                className="qty_plus"
                onClick={this.updateCartQty.bind(this, product, "incr")}
              >
                +
              </span>
            </div>
          </div>
          <a
            href="/"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          >
            <img src={crossImg} />
          </a>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div className="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+ S$" + modVlPrice + ")" : "";
        temp_html +=
          "<p>" + modName + ":</p> <p>" + modval + newModVlPrice + "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+ S$" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice =
          modValPrice > 0 ? " (+ S$" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(productId, cartItemId, cartQty);
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(productId, cartItemId, cartQty);
    }
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut(event) {
    event.preventDefault();

    var cartTotal = this.props.cateringCartTotalItmCount;
    if (parseFloat(cartTotal) > 0) {
      if ($("body").width() <= 980) {
        if ($(".product-rhs .hcart_dropdown.active").length > 0) {
          $(".product-rhs .hcart_dropdown").removeClass("active");
        } else {
          $(".product-rhs .hcart_dropdown").addClass("active");
        }
      } else {
        if ($("#firstcategory").length > 0) {
          this.props.history.push("/catering/" + $("#firstcategory").val());
        } else {
          cookie.save("cateringCheckoutFromCart", "Yes", { path: "/" });
          this.props.history.push("/catering-checkout");
        }
      }
    }
  }

  render() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    return (
      <>
        {this.props.pageName === "header" &&
          (avltyTxt === cateringId ? (
            <li className="htico_cart" key="6">
              <a
                href="/"
                onClick={this.gotoCateringChkut.bind(this)}
                className="hcartdd_trigger"
                title=""
              >
                <img src={shoppingBag} alt="" />
                <span className="hcart_round">
                  {this.props.cateringCartTotalItmCount}
                </span>
              </a>
              <input
                type="hidden"
                value={this.props.cateringCartTotalItmCount}
                id="totalitmcount"
              />
            </li>
          ) : (
            <li className="htico_cart" key="7">
              <Link to={"/checkout"} className="hcartdd_trigger" title="">
                <img src={shoppingBag} alt="" />
                <span className="hcart_round">
                  {this.props.cartTotalItmCount}
                </span>
              </Link>
              <input
                type="hidden"
                value={this.props.cartTotalItmCount}
                id="totalitmcount"
              />
            </li>
          ))}
        <li key="8">{this.cartDetailsList()}</li>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
