/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";
class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedNavigation: "",
    };
  }

  componentWillReceiveProps(pdtProps) {
    if (
      this.state.selectedNavigation !== pdtProps.productState.selectedNavigation
    ) {
      this.setState({
        selectedNavigation: pdtProps.productState.selectedNavigation,
      });
    }
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.state.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index <= 5) {
          if (loaddata.menu_type == "main") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/#" + loaddata.pro_cate_slug}
                  onClick={(e) => {
                    e.preventDefault();
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/"}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          }
        }
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".product-menu .menu-list-intvl a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 320;

                if (
                  toppos <= scrollPos &&
                  toppos + refElement.height() > scrollPos
                ) {
                  $(".product-menu .menu-list-intvl").removeClass("active");
                  currLink.parents(".menu-list-intvl").addClass("active");
                } else {
                  currLink.parents(".menu-list-intvl").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if ($(".menu-list-intvl.active").length === 0) {
                $(".product-menu .menu-list-intvl:first-child").addClass(
                  "active"
                );
              }
            });
        });
      }, 100);
      return mainMenu;
    } else {
      return;
    }
  }

  navigateMobileMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/category/" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }
  navigateMenuListHidden() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var i = 0;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index > 5) {
          i++;
          if (loaddata.menu_type == "main") {
            return (
              <li
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/#" + loaddata.pro_cate_slug}
                  className="menu-title-link"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </li>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <li
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </li>
            );
          }
        }
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".more-menu ul li a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));

              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 320;
                if (
                  toppos <= scrollPos &&
                  parseFloat(toppos) + parseFloat(refElement.height()) >
                    scrollPos
                ) {
                  $(".more-menu ul li").removeClass("active");
                  $(".more-menu-parent, .more_categor_info").addClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  $(".more-menu-parent, .more_categor_info").removeClass(
                    "active"
                  );
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if ($(".more-menu .menu-list-intvl.active a").length > 0) {
                $(".more-menu .more-menu-name").html(
                  $(".more-menu .menu-list-intvl.active a").text()
                );
              } else {
                $(".more-menu .more-menu-name").html("Read more");
              }
            });
        });
      }, 100);
      if (i > 0) {
        $(".more-menu").show();
      } else {
        $(".more-menu").hide();
      }
      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts(selectedNavigation) {
    this.setState({ selectedNavigation: selectedNavigation });
    $("html, body").animate(
      {
        scrollTop: $("#pro_cat_" + selectedNavigation).offset().top - 200,
      },
      1000
    );
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  render() {
    var settingsGallery = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,

      responsive: [
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      <>
        <div className="menu-section-left">
           <div className="container">
            <div className="menu-section-left-inner product-menu">
              {this.navigateMenuList()}
            </div>
            <div className="more-menu open_more_categories" style={{ display: "none" }}>
              <div className="more-menu-parent">
                <span className="more-menu-name">Read More</span>
                <label className="open_more_category">
                  {" "}
                  <i className="fa fa-angle-double-down"></i>{" "}
                </label>
              </div>
              <ul style={{ display: "none" }} className="more_categor_info">
                {this.navigateMenuListHidden()}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="menu-section-mobile-inner">
          <Slider {...settingsGallery}>{this.navigateMobileMenuList()}</Slider>
        </div> */}
      </>
    );
  }
}

export default MenuNavigation;
