import { SET_CATEGORY_LIST } from '../actions';

const cateringcategory = (state = [], action) => {
  switch (action.type) {
    case SET_CATEGORY_LIST:
      return [...action.value];
    default: return state;
  }
}

export default cateringcategory;