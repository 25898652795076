/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import FavouriteproductList from "./FavouriteproductList";
import ProductDetail from "./ProductDetail";
import { baseUrl } from "../Helpers/Config";
import { showLoader } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ZONE_DETAIL,
} from "../../actions";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      defaultAvilablityId: cookie.load("defaultAvilablityId"),
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      searchProResult: [],
      selectedProResult: [],
      tagList: "",
      filterTag: [],
      setFiltetTag: "No",
      productload: "Yes",
    };

    var cust_id =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== "undefined" &&
      typeof cookie.load("UserId") !== undefined
        ? cookie.load("UserId")
        : "";
    if (cust_id === "") {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      location.href = baseUrl;
      return;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
    if (field === "resetslug" && value !== "") {
      if (value === 1) {
        this.setState({ viewProductSlug: "" });
      }
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").show();
    $(".hide_product_details").hide();
  }

  render() {
    return (
      <div className="productpage-main-div">
        <div className="producthea">
          {/* Header start */}
          <Header
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
            showCatryName={this.state.selectedCategoryName}
          />
          {/* Header End */}
          <section className="product-menu-listing">
            <div className="ourmenu hide_product_details">
              <h2>Our Favourite</h2>
            </div>
            <div className="container">
              <ProductDetail
                productState={this.state}
                sateValChange={this.sateValChange}
              />
              <FavouriteproductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
          </section>
        </div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
